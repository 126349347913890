import * as React from 'react'
import { Helmet } from 'react-helmet'
import LayoutService from '../templates/LayoutService'
import { StaticImage } from 'gatsby-plugin-image'
import '../styles/components/titles.module.scss'
import '../styles/components/header.module.scss'
import '../styles/components/content.module.scss'
import { bannerBlock, bannerBlockHeadline, bannerBlockTagline, bannerBlockTitleSm, pageContentBlock, pageContentMedia } from '../styles/components/blocks.module.scss'
import { imgBox } from '../styles/components/boxes.module.scss'
import ServicesList from '../components/blocks/ServicesList'

const AboutPage = () => {
  return (
    <LayoutService>
      <Helmet>
        <title>About Us | Tampa Bay Boating Adventures</title>
        <meta name="description" content="Re-defining party boats in Tampa. No crusty captains, no lame pontoons. Just pure, unadulterated partying. This is how Tampa parties on a boat." />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <header className='container'>
        <div className='row'>
          <div className='col-xxs-12'>
            <div className={bannerBlock}>
              <div className={imgBox}>
                <StaticImage
                  alt="TBBA owners pointing at their party boat logo in Tampa Bay, FL."
                  src="../images/banners/tbba-owners-pointing-at-party-boat-logo.png"
                />
              </div>
              <div className={bannerBlockHeadline}>
                <h1 className={bannerBlockTagline}>About Tampa Bay Boating Adventures</h1>
                <span className={bannerBlockTitleSm}>Who We Are</span>
              </div>
            </div>
          </div>
        </div>
      </header>
      <main className='container'>
        <div className='row'>
          <div className='col-xxs-12'>
            <div className={pageContentBlock}>
              <h2>Tampa Bay Boating Adventures is a private party boat charter service located in Tampa Bay, FL.</h2>
              <h3>We provide the full party boat experience in comfort! See the sights in and around Tampa Bay, Florida while you party on the water in Tampa's most comfortable party boat.</h3>
              <div className={pageContentMedia}>
                <div className={imgBox}>
                  <StaticImage
                    alt="Partiers bumping cups on a party boat in Tampa Bay, FL."
                    src="../images/banners/partiers-on-boat-with-drinks-bumping.jpg"
                  />
                </div>
              </div>
              <p>It’s time to party! Come explore Tampa Bay like you never have before on our booze-cruise like, private boat charters. Make memories that will last a lifetime by exploring white sand beaches only accessible by boat, cruising the Hillsborough river, and touring secret spots that we can’t give away online!</p>
              <hr />
              <h2>History of Tampa Bay Boating Adventures</h2>
              <p>We aim to explore, party, and serve. It’s as simple as that. After moving down to Tampa, the founders of Tampa Bay Boating Adventures realized that many people just like ourselves had moved down to the Tampa Bay area with an expectation of exploring the area by water, seeing dolphins, watching sunset from secluded islands, and exploring the white sand beaches you see in postcards. Turns out - there’s no way to do that. Tampa Bay Boating Adventures is your answer. We’ll show you the spots you’ve dreamed about. These places exist and we know the best places to explore - including those you may have heard about and many that you haven’t. We’ve spent countless trips refining our favorite spots in order to show you what Tampa Bay truly has to offer. Party all day and explore our beautiful city with Tampa Bay Boating Adventures.</p>
              <hr />
              <h2>The Boat</h2>
              <div className={pageContentMedia}>
                <div className={imgBox}>
                  <StaticImage
                    alt="People partying on a boat in Tampa Bay, Florida." 
                    src="../images/banners/people-partying-on-boat-in-tampa-bay-florida.jpg"
                  />
                </div>
              </div>
              <p>If a boat party is what you want - you’re in the right spot! You’ll find premium, new two-tone “leather” seats to lounge on, an elevated tanning platform on the back of the boat (yes, really!), a state-of-the-art sound system for all day partying, and more room than you’ll need! The days of crowded, uncomfortable boat trips are over. Let’s put it this way; the boat can hold 16 people - and you only need 6 seats! No more bumping knees or fighting for a spot. Our team has put countless hours into ensuring that your trip is as smooth and seamless as possible. With more coolers, seats, and cupholders then we know what to do with, you can’t go wrong on a Tampa Bay Boating Adventure! Explore and party on Tampa’s most comfortable party boat!</p>
              <hr />
              <h2>Snacks</h2>
              <p>Don’t worry about bringing water, snacks, or any of those other clunky items that take away from a trip. Our team will provide enough waters for your entire trip, complimentary snacks, and even a few extra surprises we can’t mention here. Our boat will also include the occasional spare towel, sunscreen, and the other necessities that are often forgotten. No need to worry about a cooler or ice either! The boat has integrated coolers to keep your drinks cool as you party hard.</p>
              <hr />
              <h2>Choose a Private Party Boat Charter Service with Tampa Bay Boating Adventures!</h2>
              <ServicesList />
            </div>
          </div>
        </div>
      </main>
    </LayoutService>
  )
}

export default AboutPage